<template>
  <div class="login">

    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>

    <div class="set">
      <div class="set-left">
        <div class="set-left-avatar">
          <div class="set-left-img">
            <img v-if="this.userGender == '男'" src="@/assets/login/默认头像-男.png">
            <img v-else src="@/assets/login/默认头像-女.png">
          </div>
          <!-- <div class="set-left-status" v-if="!userMsg.identityType" @click="toAuthentication">去认证></div> -->
          <div class="set-left-user">{{ userName }}</div>
          <div class="set-shadow">
            <div class="set-left-details">
              <div v-for="item in subTypeCourseList" style="flex: 1; text-align: center;">
                <el-radio-group v-model="subTypeCourse">
                <el-radio :label="item" @change="subTypeCourseClick(item)"></el-radio>
              </el-radio-group>
              </div>
            </div>
            <div class="set-left-hour">
              <div>学时 : {{ userLearned.obtainedHours }}/{{ userLearned.totalHours }}</div>
              <div>课程 : {{ userLearned.learned }}/{{ userLearned.selected }} </div>
            </div>
          </div>
          <div class="set-menu">
            <el-menu text-color="#3E454D" active-text-color="#1371E7" :default-active="btn" router
              class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
              <el-menu-item index="PersonalHome">
                <i class="el-icon-s-home"></i>
                <span slot="title">我的课程</span>
              </el-menu-item>
              <el-menu-item index="CourseStatistics">
                <i class="el-icon-date"></i>
                <span slot="title">课程统计</span>
              </el-menu-item>
              <el-menu-item index="MineCert">
                <i class="el-icon-document-checked"></i>
                <span slot="title">我的证书</span>
              </el-menu-item>
              <el-menu-item index="MineOrder">
                <i class="el-icon-tickets"></i>
                <span slot="title">我的订单</span>
              </el-menu-item>
              <!-- <el-menu-item index="BillApply">
                <i class="el-icon-receiving"></i>
                <span slot="title">发票申请</span>
              </el-menu-item> -->
              <el-submenu index="/">
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span>我的设置</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="PersonalCenter">个人资料</el-menu-item>
                  <el-menu-item index="SafeCenter">安全中心</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
      <div class="set-right">
        <router-view />
      </div>
    </div>



    <footerBottom></footerBottom>
  </div>
</template>

<script>
import { httpNPGet } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"
import footerBottom from '@/components/footer/footer'

export default {
  name: 'Login',
  data() {
    return {
      // 当前选项卡
      btn : '',
      // 用户性别
      userGender: '',
      userName: localStorage.getItem('userName'),
      pageIndex: 3,
      // 浮动框
      xPos: document.body.clientWidth - 500,
      yPos: 100,
      xin: true,
      yin: true,
      step: 1,
      delay: 20,
      height: 0,
      heightSet: 0,
      widthSet: 0,
      yon: 0,
      xon: 0,
      pause: true,
      promptShow: true,
      // 用户信息
      userMsg: {},
      //课程类型
      subTypeCourse: "",
      subTypeCourseList: [],
      userLearned: {},
    }
  },
  components: {
    topNav,
    footerBottom
  },
  computed: {},
  created() {
    // if (!localStorage.getItem("userId")) {
    //   // this.$message({
    //   //   message: '请先登录',
    //   //   type: 'error'
    //   // })
    //   this.$router.push("/")
    //   this.$router.push("login")
    // }
      this.GetUserMsg()
  },
  mounted() {
    this.userGender = localStorage.getItem("userGender")
    this.btn = this.$router.history.current.name
  },
  methods: {
    // 获取用户学习信息数
    GetUserMsg() {
      httpNPGet("/app/app-user-credit-hours/detail",
        (res) => {
          this.userMsg = res.data
          this.subTypeCourseList.push("全部");
          for (const key in res.data) {
            // console.log(key);
            if (key !== "全部") {
              this.subTypeCourseList.push(key);
            }
          }
          this.subTypeCourse = this.subTypeCourseList[0]
          this.subTypeCourseClick(this.subTypeCourseList[0])
        }, (err) => {
          // console.log(err)
        })
    },
    // 用户信息切换
    subTypeCourseClick(item){
      // console.log(this.userMsg[item])
      this.userLearned = this.userMsg[item]
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    /**
     * 点击切换标题
     * @param {number} index 菜单序列(点击的第index个)
     */
    changeTitle(index) {
      this.active = index;
      // console.log('===========', index)
      this.setUnderLine();
    },
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 58px;
  height: 58px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  height: 40px;
  line-height: 40px;
  background-color: #303030;
  color: aliceblue;
  font-size: 14px;

  .header-top {
    display: flex;
    justify-content: space-between;
    background-color: #303030;
    color: aliceblue;
  }

  .header-top-right,
  .header-top-right>div {
    display: flex;
    align-items: center;
  }

  .header-top-right img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}

.nav {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #fff !important;
}

.nav-f {
  width: 100%;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  //position: fixed;
  //top: 0;
  //left: 0;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #333 !important;
}

.nav1 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 80px;
    /* line-height: 80px; */

    .nav-left h2 {
      font-size: 24px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      line-height: 76px;
      letter-spacing: 2px;
    }

    .nav-left span {
      width: 1px;
      height: 26px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      margin: 0 15px;
      opacity: 0.2;
    }
  }

  .nav-center {
    width: 600px;

    .nav-list {
      /* margin: 0 auto;
      max-width: 600px; */
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 78px;
    }

    .nav-list>div {
      width: 600px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      text-align: center;
      border-right: solid 0.2px #d0d0d0;
    }

    .nav-list>div:last-child {
      border: none;
    }

    .nav-list--active {
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
    }
  }

  .nav-line {
    margin: 0 auto;
  }

  .nav-line>div {
    /*width: 32px;*/
    height: 4px;
    background-color: #1371E7;
    /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
    border-radius: 2px;
    transition-duration: 0.3s;
    position: relative;
    top: 0px;
  }

  .nav-center-btn {
    /* height: 40px; */
    width: 122px;
    height: 40px;
    background: #1371e7;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}


.set {
  width: 1200px;
  margin: 1.875rem auto 5rem auto;
  display: flex;
  justify-content: space-between;

  .set-left {
    min-width: 180px;
    width: 17.7966%;
    height: 49.5rem;
    background-color: #FFFFFF;
       border-radius: 10px;

    .set-left-avatar {
      position: relative;
      height: 16.9375rem;
      background: linear-gradient(352deg, #005FBC 0%, #178CFF 100%);
      border-radius: 10px 10px 0 0 ;

      .set-left-img {
        text-align: center;
        margin: 0 auto;
        padding-top: 1.5rem;
        padding-bottom: 1rem;

        img {
          width: 6rem;
          height: 6rem;
          border: 0.1875rem solid #EDEFF2;
          border-radius: 50%;
        }
      }

      .set-left-status {
        width: 4.9375rem;
        height: 1.3125rem;
        background: rgba(0, 0, 0, 0.33);
        border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
        font-size: 0.75rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.3125rem;
        position: absolute;
        top: 6.875rem;
        left: 31%;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .set-left-status::before {
        content: "";
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: url('~@/assets/img/pre/icon-date.png');
        background-size: 100% 100%;
        margin-right: 0.25rem;
        margin-left: 0.3125rem;
      }

      .set-left-user {
        height: 1.5625rem;
        font-size: 1.125rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 1.125rem;
        text-align: center;
      }

      .set-shadow {
        width: 100%;
        height: 5.875rem;
        margin-top: 0.625rem;
        background: rgba(0, 0, 0, 0.1);

        .set-left-details {
          display: flex;
          justify-content: space-between;
          //width: 11.0625rem;
          margin: 0 auto 0 auto;
          padding: 1rem 20px;
          height: 1.0625rem;
          font-size: 0.75rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4F90D3;
          line-height: 0.875rem;
          border-bottom: solid 0.0625rem;



          ::v-deep .el-radio__inner {
            display: none;
          }


          @media screen and (max-width: 1920px) {
            ::v-deep .el-radio {
              padding: 0 11%;
            }
          }

          @media screen and (max-width: 1780px) {
            ::v-deep .el-radio {
              padding: 0 8%;
            }
          }

          @media screen and (max-width: 1680px) {
            ::v-deep .el-radio {
              padding: 0 6.5%;
            }
          }

          @media screen and (max-width: 1580px) {
            ::v-deep .el-radio {
              padding: 0 5.2%;
            }
          }

          @media screen and (max-width: 1480px) {
            ::v-deep .el-radio {
              padding: 0 4%;
            }
          }

          @media screen and (max-width: 1380px) {
            ::v-deep .el-radio {
              padding: 0 3%;
            }
          }

          ::v-deep .el-radio__input.is-checked+.el-radio__label {
            color: #FFFFFF;
          }

          ::v-deep .el-radio.is-checked {
            color: #FFFFFF;
          }

          ::v-deep .el-radio__label {
            padding-left: 0;
            color: #4F90D3;
          }
        }

        .set-left-hour {
          display: flex;
          justify-content: space-between;
          padding: 0.875rem 15%;
          height: 1.0625rem;
          font-size: 0.75rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 0.6;
          line-height: 1.0625rem;
        }
      }

      .set-menu {
        width: 90%;
        margin: 1rem auto 0 auto;
        height: 30rem;

        ::v-deep .el-menu {
          border-right: none;
        }

        ::v-deep .el-submenu__title {
          text-align: left;
          //width: 11.125rem;
          height: 2.5rem;
          margin-top: 0.75rem;
          line-height: 2.5rem;
          font-size: 0.875rem;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #3E454D;
        }

        ::v-deep .el-icon-location {
          padding-right: 0.75rem;
        }

        ::v-deep .el-menu-item [class^=el-icon-] {
          text-align: left;
          padding-right: 0.75rem;

        }

        ::v-deep .el-menu-item-group>ul {
          overflow: hidden;
        }

        ::v-deep .el-submenu .el-menu-item {
          padding-left: 3.875rem !important;
          margin-top: 0.75rem;
          font-size: 0.875rem;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #3E454D;
        }

        ::v-deep .el-menu-item {
          text-align: left;
          width: 100%;
          height: 2.5rem;
          margin-top: 0.75rem;
          line-height: 2.5rem;
          font-size: 0.875rem;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #3E454D;
          margin-left: 0;
        }

        ::v-deep.el-menu-item:focus,
        .el-menu-item:hover {
          background: rgba(9, 112, 213, 0.1);
          // border-radius: 10px;
        }

        ::v-deep .el-menu-item.is-active {
          background: rgba(9, 112, 213, 0.1);
          // border-radius: 10px;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  .set-right {
    min-width: 660px;
    width: 80.169%;
    height: 49.5rem;
    margin-left: 20px;
    border-radius: 10px;
  }
}

.tip {
  width: 61.458%;
  margin: 1.25rem auto 1.75rem auto;
  background: rgba(242, 74, 74, 0.05);
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  height: 5.5rem;
  display: flex;
  align-items: center;

  img {
    width: 3.898%;
    margin: 1.3125rem 0 1.3125rem 1.5rem;
  }

  .tip-content {
    width: 88.136%;
    text-align: left;
    margin: 1.5rem 1rem;
    font-size: 0.875rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #F24A4A;
    line-height: 1.5rem;
  }
}

.bottom {
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/img/pre/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}

.footer {
  padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

  .footer-content {
    width: 61.458%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 1.5rem auto;
    padding: 2rem 0 0 0;
    align-items: center;

    .footer-top-line-1 {
      width: inherit;
      height: 0;
      opacity: 0.4;
      border: 0.0625rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
    }

    .footer-top-line-2 {
      width: inherit;
      height: 0;
      opacity: 0.4;
      border: 0.0625rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
      transform: rotate(180deg);
    }

    .footer-top-line-font {
      margin: 0 1.1%;
      font-size: 0.75rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.47);
      line-height: 1.25rem;
      width: 70%;
    }

    @media screen and (max-width: 1539px) {
      .footer-top-line-font {
        width: 120%;
      }
    }

    @media screen and (max-width: 1400px) {
      .footer-top-line-font {
        width: 130%;
      }
    }
  }

  .footer-detail {
    width: 61.458%;
    //background-color: red;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: left;
    font-size: 0.875rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.25rem;
    align-items: center;

    .line {
      width: 1px;
      height: 0.875rem;
      background: #FFFFFF;
      opacity: 0.2;
    }

    .code-logo {
      width: 35.87%;
    }

    .code-img {
      // width: 100%;
      height: 5.9375rem;
    }

    ul {
      list-style-type: none;
      margin-top: 1.75rem;
      display: flex;

      justify-content: space-between;

    }
  }
}

.login {
  width: 100%;
  height: 100%;
  background-color: rgb(247, 247, 247);
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%,-50%);*/
  /*width: auto;*/
  /*height:auto;*/
}

.nav {
  width: 100%;
  height: 5rem;
  background-color: rgba(203, 186, 186, 0.24);
}

.nav .nav-list {
  margin: 0 auto;
  max-width: 37.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 4.75rem;
  /*background: #d40d0d;*/
}

.nav .nav-list>div {
  width: 100%;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #666;
  line-height: 1rem;
  cursor: pointer;
  text-align: center;
  border-right: solid;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  /*-webkit-background-clip: text;*/
  /*-webkit-text-fill-color: transparent;*/

}

.nav .nav-list>div:last-child {
  border: none;
}

.nav .nav-list .nav-list--active {
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  font-weight: bold;
  color: #333333;

  /*transition: all 0.3s;*/
  /*background-color: #FFE53B;*/
  /*background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);*/
}

.nav-line {
  margin: 0 auto;
}

.nav-line>div {
  /*width: 32px;*/
  height: 0.25rem;
  background-color: #333333;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  border-radius: 0.125rem;
  transition-duration: 0.3s;
  position: relative;
  top: 0;
}

.top {
  width: 100%;
  height: 2.5rem;
  background: #303030;
}
</style>
